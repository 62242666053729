
import React from 'react'

import { Header } from 'modules/index/header'
import { Browse } from 'modules/index/browse'

export default class Index extends React.Component {

    constructor(props) {
        super(props)
        this.newGeneratorInput = React.createRef()
    }

    render() {

        return <>
            <Header />
            <Browse />
        </>
    }

}