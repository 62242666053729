
import styled from 'styled-components'

export const Arrow = styled.div`

    transform:      scale(${props => props.scale || 1});
    position:       relative;
    flex-shrink:    0;
    display:        inline-block;
    width:          24px;
    height:         12px;
    padding:        0;

    &:before, &:after {
        display:    block;
        position:   absolute;
        top:        50%;
        width:      10px;
        height:     2px;
        background-color: currentColor;
        content:    '';
        transition: transform 0.25s ease, -webkit-transform 0.25s ease;
    }

    &:before {
        left:       4px;
        transform:  rotate(45deg);
    }

    &:after {
        right:      4px;
        transform:  rotate(-45deg);
    }

    ${props => props.injectStyle}
`