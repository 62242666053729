
import React from 'react'
import styled from 'styled-components'

import { Arrow } from 'components/icons/arrow'
import { DiceText } from 'components/dice-text'

const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 10%;
    padding-top: calc(20vh + 100px);
`

export const Header = (props) => {

    return <Wrapper>
        <div><DiceText text={'RANDOM'} /></div>
        <div><DiceText text={'GENERATORS'} /></div>
        <Arrow scale={2} injectStyle={`color: #ff6666; position: absolute; bottom: 40px; left: 50%; transform: translateX(-50%) scale(2);`} />
    </Wrapper>

}